<template lang="pug">
.app
  //div(style="left: 0px; top: 0px; width: 100%; height: 44px;")
  //  div(class="widget rounded_rect undefined hcenter vmiddle" style="width: 100%; height: 44px; border-color: rgb(232, 232, 232); border-width: 0px; border-style: solid; font-size: 18px; padding: 0px; text-align: center; line-height: 26px; font-weight: normal; font-style: normal; background: rgb(0, 128, 196);align-items: center;display: flex;box-sizing: border-box;margin: 0;justify-content: center;")
  //    div(style="background: transparent;color: #101010;overflow: hidden;border-color: #bbb;")
  //      img(class="img" src="@/assets/logo.png" draggable="false" style="display: block; left: 119px;top: 50px;width: 150px;height: 33px;")
  div(v-if="seq === 0")
    div(style="heidth:100%")
      div(:style="{'background-image': `url(${require('@/assets/test.png')})`,'background-repeat':'no-repeat','background-size':'100% 100%','-moz-background-size':'100% 100%', 'height':'50vh'}")
        div(style="padding-top: 45px;padding-left: 25px;height: 50px;color: rgba(255, 255, 255, 1);font-size: 36px;text-align: left;font-family: PingFangSC-regular;") 全系列导航
        div(style="padding-left: 25px;padding-top: 30px;height: 28px;color: rgba(255, 255, 255, 1);font-size: 20px;text-align: left;font-family: PingFangSC-regular;") 你平时喜欢的运动？
        div(style="padding:10px")
          el-checkbox-group(v-model="value1" size="small")
            el-checkbox-button(v-for="d in data1" :label="d.id" :key="d.id" style="border-radius:20px;padding-top:8px;padding-left:8px") {{d.name}}
          .el-form-item__error(v-if="error.value1 === true" style="top:auto") 此选择不能为空
      div(:style="{'background-image': `url(${require('@/assets/test2.png')})`,'background-repeat':'no-repeat','background-size':'100% 100%','-moz-background-size':'100% 100%', 'height':'50vh'}")
        div(style="padding-left: 25px;padding-top: 40px;height: 28px;color: rgba(255, 255, 255, 1);font-size: 20px;text-align: left;font-family: PingFangSC-regular;") 你日常穿着的内衣尺寸?
        div(style="padding:10px")
          el-radio-group(v-model="value2" size="small")
            el-radio-button(v-for="d in data2" :label="d.id" :key="d.id"  style="border-radius:20px;padding-top:8px;padding-left:8px") {{d.name}}
          .el-form-item__error(v-if="error.value2 === true" style="top:auto") 此选择不能为空
        br
        div(style="padding:20px")
          van-button(type="primary" block @click="filter()"  style="background-color: rgba(0, 128, 196, 1);border: none;") 筛选
  //div(v-if="seq === 5")
  //  van-nav-bar(left-text="返回" left-arrow @click-left="onClickLeft2")
  //  iframe(:src="url"   style="width: 100%;border: 0px;margin:0; height:90vh")
  div(v-if="seq === 1")
    div(style="heidth:100%")
      van-nav-bar(left-text="返回" left-arrow @click-left="onClickLeft2")
      div(style="padding:20px")
        div(style="height: 10vh;font-size: 36px;text-align: left;font-family: PingFangSC-regular;padding-bottom: 2vh;") 产品列表 ({{showImage.length}})
          span(@click="openFilter()" style="font-size: 15px;color:rgb(0, 128, 196);vertical-align: middle;") 查看更多
          //van-icon(:name="require('@/assets/filter.png')" size="15" style="padding-left:1vh" @click="openFilter()")

        van-swipe(class="my-swipe" style="box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);border-radius:10px 10px 10px 10px;")
          van-swipe-item(v-for="image in showImage" )
            van-image(:src="image.src" style="width: 100%;display: block;" fit="contain" @click="toUrl(image)")
              div(style="height: 5vh;float: left;width:100%;padding-top: 2vh;") {{ image.title }}
              div(style="height: 5vh;float: left;width:100%;padding-top: 2vh;") 推荐尺码 {{ recommend[value2][image.type] }}
            //van-image(:src="require('@/assets/test3.png')" style="float:left;width:50%;padding-top:1vh;height: 10vh;display: block;")
            div(style="color:#151515;width:50%;float: left;height: 10vh;padding-top: 2vh;")

  //div(v-if="seq === 2")
  //  van-nav-bar(left-text="返回" left-arrow @click-left="onClickLeft")
  //  van-swipe
  //    van-swipe-item(v-for="image in currentImage.pictures" style="350px")
  //      van-image(:src="image" style="height:400px;width:100%;")
  //  div(style="height: 80px;color:black;font-size: 28px;text-align: left;font-family: PingFangSC-regular;padding-left:70px;padding-top:20px;") {{ currentImage.title }}
  //  br
  //  div(style="padding:30px")
  //    van-button(type="primary" block @click="toUrl(currentImage)" style="background-color: rgba(0, 128, 196, 1);border: none;" v-if="currentImage.url !==''") 立即购买

  div(v-if="seq === 4")
    div
      div(style="padding-left: 25px;padding-top: 30px;height: 28px;font-size: 20px;text-align: left;font-family: PingFangSC-regular;") 你对运动内衣长短喜好?
      div(style="padding:10px")
        //el-radio-group(v-model="value3" size="small")
        //  el-radio-button(v-for="d in data3" :label="d.id" :key="d.id"  style="border-radius:20px;padding-top:8px;padding-left:8px") {{d.name}}
        el-checkbox-group(v-model="value3" size="small")
          el-checkbox-button(v-for="d in data3" :label="d.id" :key="d.id"  style="border-radius:20px;padding-top:8px;padding-left:8px") {{d.name}}
    div
      div(style="padding-left: 25px;padding-top: 30px;height: 28px;font-size: 20px;text-align: left;font-family: PingFangSC-regular;") 您对运动内衣的穿着喜好?
      div(style="padding:10px")
        el-checkbox-group(v-model="value4" size="small")
          el-checkbox-button(v-for="d in data4" :label="d.id" :key="d.id"  style="border-radius:20px;padding-top:8px;padding-left:8px") {{d.name}}
    div
      div(style="padding-left: 25px;padding-top: 30px;height: 28px;font-size: 20px;text-align: left;font-family: PingFangSC-regular;") 您对运动内衣的设计偏好?
      div(style="padding:10px")
        el-checkbox-group(v-model="value5" size="small")
          el-checkbox-button(v-for="d in data5" :label="d.id" :key="d.id"  style="border-radius:20px;padding-top:8px;padding-left:8px") {{d.name}}
    br
    div(style="padding:30px")
      van-button(type="primary" block @click="submit" style="background-color: rgba(0, 128, 196, 1);border: none;") 确定

</template>

<script>
export default {
  name: 'login',
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      {
        name: 'viewport',
        content:
          'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1',
      },
    ],
  },
  data () {
    return {
      seq: 0,
      error: {
        value1: false,
        value2: false,
      },
      currentImage: {},
      recommend: {
        '70A-70B': { Kalenji: 'XS-AB', Domyos: 'XS', Dance: '34' },
        '75A-75B': { Kalenji: 'S-AB', Domyos: 'S', Dance: '36' },
        '70C-70D': { Kalenji: 'S-CD', Domyos: 'S', Dance: '36' },
        '80A-80B': { Kalenji: 'M-AB', Domyos: 'M', Dance: '38' },
        '75C-75D': { Kalenji: 'M-CD', Domyos: 'M', Dance: '38' },
        '70E-70F': { Kalenji: 'M-EF', Domyos: '', Dance: '' },
        '85A-85B': { Kalenji: 'L-AB', Domyos: 'L', Dance: '40' },
        '80C-80D': { Kalenji: 'L-CD', Domyos: 'L', Dance: '40' },
        '75E-75F': { Kalenji: 'L-EF', Domyos: '', Dance: '' },
        '90A-90B': { Kalenji: 'XL-AB', Domyos: 'XL', Dance: '42' },
        '85C-85D': { Kalenji: 'XL-CD', Domyos: 'XL', Dance: '42' },
        '80EF': { Kalenji: 'XL-EF', Domyos: '', Dance: '' },
        '90CD': { Kalenji: '2XL-CD', Domyos: '2XL', Dance: '44' },
        '85EF': { Kalenji: '2XL-EF', Domyos: '2XL', Dance: '44' },
        '95CD': { Kalenji: '3XL-CD', Domyos: '', Dance: '' },
        '90EF': { Kalenji: '3XL-EF', Domyos: '', Dance: '' },
      },
      data2: [
        { id: '70A-70B', name: '70A-70B' },
        { id: '75A-75B', name: '75A-75B' },
        { id: '70C-70D', name: '70C-70D' },
        { id: '80A-80B', name: '80A-80B' },
        { id: '75C-75D', name: '75C-75D' },
        { id: '70E-70F', name: '70E-70F' },
        { id: '85A-85B', name: '85A-85B' },
        { id: '80C-80D', name: '80C-80D' },
        { id: '75E-75F', name: '75E-75F' },
        { id: '90A-90B', name: '90A-90B' },
        { id: '85C-85D', name: '85C-85D' },
        { id: '80EF', name: '80EF' },
        { id: '90CD', name: '90CD' },
        { id: '85EF', name: '85EF' },
        { id: '95CD', name: '95CD' },
        { id: '90EF', name: '90EF' },
      ],
      images: [
        {
          title: '高强度运动内衣-多功能肩带系列',
          cc: '308272',
          src: require('@/assets/images/308272.png'),
          type: 'Kalenji',
          value: 3,
          value2: [
            '70A-70B',
            '75A-75B',
            '70C-70D',
            '80A-80B',
            '75C-75D',
            '85A-85B',
            '80C-80D',
            '90A-90B',
            '85C-85D',
            '90CD',
            '85EF',
          ],
          value3: 1,
          value4: [3, 1],
          value5: [3],
          url: 'https://www.decathlon.com.cn/zh/p/classic-running-sports-bra/_/R-p-308272?mc=8664570&c=%E7%B4%AB%E8%89%B2',
        },
        {
          title: '女式瑜伽运动内衣',
          cc: '324539',
          src: require('@/assets/images/324539.png'),
          type: 'Kalenji',
          value: 3,
          value2: [
            '70A-70B',
            '75A-75B',
            '70C-70D',
            '80A-80B',
            '75C-75D',
            '85A-85B',
            '80C-80D',
            '90A-90B',
            '85C-85D',
            '90CD',
            '85EF',
          ],
          value3: 1,
          value4: [4],
          value5: [3],
          url: 'https://www.decathlon.com.cn/zh/p/cn-bra-action-520/_/R-p-343183?mc=8781245&c=%E6%A3%95%E8%89%B2',
        },
        {
          title: '女式有氧健身运动内衣',
          cc: '311979',
          name: 'image14.png',
          src: require('@/assets/images/311979.png'),
          type: 'Domyos',
          value: 3,
          value2: [
            '70A-70B',
            '75A-75B',
            '70C-70D',
            '80A-80B',
            '75C-75D',
            '85A-85B',
            '80C-80D',
            '90A-90B',
            '85C-85D',
            '90CD',
            '85EF',
          ],
          value3: 1,
          value4: [4, 1],
          value5: [3],
          url: 'https://www.decathlon.com.cn/zh/p/large-high-support-fitness-bra-960/_/R-p-311979?mc=8569102&c=%E9%BB%91%E8%89%B2',
        },
        {
          title: '女式有氧健身运动内衣',
          cc: '323708',
          name: 'image15.jpg',
          src: require('@/assets/images/323708.png'),
          type: 'Domyos',
          value: 3,
          value2: [
            '70A-70B',
            '75A-75B',
            '70C-70D',
            '80A-80B',
            '75C-75D',
            '85A-85B',
            '80C-80D',
            '90A-90B',
            '85C-85D',
            '90CD',
            '85EF',
          ],
          value3: 1,
          value4: [2, 1],
          value5: [],
          url: 'https://www.decathlon.com.cn/zh/p/high-support-zip-front-fitness-bra-920/_/R-p-323708?mc=8666355&c=%E7%B4%AB%E8%89%B2',
        },
        {
          title: '女式有氧健身运动内衣',
          cc: '323753',
          name: 'image16.jpg',
          src: require('@/assets/images/323753.png'),
          type: 'Domyos',
          value: 3,
          value2: [
            '70A-70B',
            '75A-75B',
            '70C-70D',
            '80A-80B',
            '75C-75D',
            '85A-85B',
            '80C-80D',
            '90A-90B',
            '85C-85D',
            '90CD',
            '85EF',
          ],
          value3: 1,
          value4: [3, 1],
          value5: [],
          url: 'https://www.decathlon.com.cn/zh/p/high-support-fitness-bra-900/_/R-p-323753?mc=8606949&c=%E8%93%9D%E8%89%B2',
        },
        {
          title: '高强度防震跑步前拉链内衣健身外穿长款文胸',
          cc: '349594',
          name: 'image17.jpg',
          src: require('@/assets/images/349594.png'),
          type: 'Domyos',
          value: 3,
          value2: [
            '70A-70B',
            '75A-75B',
            '70C-70D',
            '80A-80B',
            '75C-75D',
            '85A-85B',
            '80C-80D',
            '90A-90B',
            '85C-85D',
            '90CD',
            '85EF',
          ],
          value3: 2,
          value4: [2, 1],
          value5: [4],
          url: 'https://detail.tmall.com/item.htm?spm=a230r.1.14.11.2ff56444IwSke0&id=714499856572&ns=1&abbucket=6',
        },
        {
          title: '女式健身运动内衣',
          cc: '342806',
          name: 'image18.png',
          src: require('@/assets/images/342806.png'),
          type: 'Domyos',
          value: 3,
          value2: [
            '70A-70B',
            '75A-75B',
            '70C-70D',
            '80A-80B',
            '75C-75D',
            '85A-85B',
            '80C-80D',
            '90A-90B',
            '85C-85D',
            '90CD',
            '85EF',
          ],
          value3: 1,
          value4: [4, 1],
          value5: [4],
          // url: "https://detail.tmall.com/item.htm?abbucket=13&id=667750118219&rn=9be3c313222481bf1baf724f254dca04&spm=a1z10.15-b-s.w4011-14623109809.134.6ec579eeq2JREq",
          url: 'https://www.decathlon.com.cn/zh/p/cn-520-action-bra-zip/_/R-p-342806?mc=8767526&c=%E7%BB%BF%E8%89%B2_%E9%BB%84%E8%A4%90%E8%89%B2',
        },
        {
          title: '女式瑜伽运动内衣',
          cc: '343183',
          name: 'image19.png',
          src: require('@/assets/images/343183.png'),
          type: 'Domyos',
          value: 1,
          value2: [
            '70A-70B',
            '75A-75B',
            '70C-70D',
            '80A-80B',
            '75C-75D',
            '85A-85B',
            '80C-80D',
            '90A-90B',
            '85C-85D',
            '90CD',
            '85EF',
          ],
          value3: 1,
          value4: [5],
          value5: [3],
          // url: "https://detail.tmall.com/item.htm?abbucket=6&id=667998853906&ns=1&spm=a230r.1.14.67.38e93f62zv3Bt3",
          url: 'https://www.decathlon.com.cn/zh/p/cn-bra-action-520/_/R-p-343183?mc=8781245&c=%E6%A3%95%E8%89%B2',
        },
        {
          title: '高强度运动内衣-裸感系列',
          cc: '326651',
          name: 'image20.png',
          src: require('@/assets/images/326651.png'),
          type: 'Domyos',
          value: 3,
          value2: [
            '70A-70B',
            '75A-75B',
            '70C-70D',
            '80A-80B',
            '75C-75D',
            '85A-85B',
            '80C-80D',
            '90A-90B',
            '85C-85D',
            '90CD',
            '85EF',
          ],
          value3: 1,
          value4: [3],
          value5: [3],
          url: 'https://www.decathlon.com.cn/zh/p/max-support-nude-comfort-sports-bra/_/R-p-326651?mc=8664598&c=???',
        },
        {
          title: '高强度保护型跑步运动内衣 KOKOON V2',
          cc: '338890',
          name: 'image21.jpg',
          src: require('@/assets/images/338890.png'),
          type: 'Kalenji',
          value: 3,
          value2: [
            '70A-70B',
            '75A-75B',
            '70C-70D',
            '80A-80B',
            '75C-75D',
            '85A-85B',
            '80C-80D',
            '90A-90B',
            '85C-85D',
            '90CD',
            '85EF',
          ],
          value3: 1,
          value4: [4, 1],
          value5: [],
          url: 'https://www.decathlon.com.cn/zh/p/jog-kokoon-running-bra-high-support/_/R-p-338890?mc=8736840&c=%E7%B2%89%E7%BA%A2%E8%89%B2',
        },
        {
          title: '高强度运动内衣-洞洞杯系列',
          cc: '302407',
          name: 'image22.jpg',
          src: require('@/assets/images/302407.png'),
          type: 'Kalenji',
          value: 3,
          value2: [
            '70A-70B',
            '75A-75B',
            '70C-70D',
            '80A-80B',
            '75C-75D',
            '85A-85B',
            '80C-80D',
            '90A-90B',
            '85C-85D',
            '90CD',
            '85EF',
          ],
          value3: 1,
          value4: [3, 1],
          value5: [3, 4],
          url: 'https://www.decathlon.com.cn/zh/p/comfort-running-bra-camo/_/R-p-302407?mc=8595387&c=%E6%A9%98%E9%BB%84%E8%89%B2_%E7%B2%89%E7%BA%A2%E8%89%B2',
        },
        {
          title: '女式有氧健身运动内衣',
          cc: '114694',
          name: 'image24.png',
          src: require('@/assets/images/114694.png'),
          type: 'Domyos',
          value: 1,
          value2: [
            '70A-70B',
            '75A-75B',
            '70C-70D',
            '80A-80B',
            '75C-75D',
            '85A-85B',
            '80C-80D',
            '90A-90B',
            '85C-85D',
            '90CD',
            '85EF',
          ],
          value3: 1,
          value4: [5],
          value5: [3],
          url: 'https://www.decathlon.com.cn/zh/p/comfort-padded-women-s-fitness-sports-bra/_/R-p-141551?mc=8544371&c=%E7%BB%BF%E8%89%B2_%E9%BB%84%E8%A4%90%E8%89%B2',
        },
        {
          title: '女式有氧健身运动内衣 - 印花',
          cc: '335455',
          name: 'image26.png',
          src: require('@/assets/images/335455.png'),
          type: 'Domyos',
          value: 2,
          value2: [
            '70A-70B',
            '75A-75B',
            '70C-70D',
            '80A-80B',
            '75C-75D',
            '85A-85B',
            '80C-80D',
            '90A-90B',
            '85C-85D',
            '90CD',
            '85EF',
          ],
          value3: 1,
          value4: [5],
          value5: [],
          url: 'https://www.decathlon.com.cn/zh/p/medium-support-fitness-bra-500/_/R-p-335455?mc=8775837&c=%E7%99%BD%E8%89%B2',
        },
        {
          title: '中度支撑运动内衣',
          cc: '341114',
          name: 'image27.png',
          src: require('@/assets/images/341114.png'),
          type: 'Domyos',
          value: 2,
          value2: [
            '70A-70B',
            '75A-75B',
            '70C-70D',
            '80A-80B',
            '75C-75D',
            '85A-85B',
            '80C-80D',
            '90A-90B',
            '85C-85D',
            '90CD',
            '85EF',
          ],
          value3: 1,
          value4: [2],
          value5: [],
          // url: "https://detail.tmall.com/item.htm?abbucket=13&id=697334943234&rn=cb86b370ebf2be65e6aecefc0c8ea0d7&spm=a1z10.5-b-s.w4011-14623109101.120.682c2748JNhgNA",
          url: 'https://www.decathlon.com.cn/zh/p/medium-support-fitness-zipped-sports-bra-540/_/R-p-341114?mc=8757818&c=%E9%BB%91%E8%89%B2',
        },
        {
          title: '女式有氧健身运动内衣',
          cc: '341163',
          name: 'image28.png',
          src: require('@/assets/images/341163.png'),
          type: 'Domyos',
          value: 1,
          value2: [
            '70A-70B',
            '75A-75B',
            '70C-70D',
            '80A-80B',
            '75C-75D',
            '85A-85B',
            '80C-80D',
            '90A-90B',
            '85C-85D',
            '90CD',
            '85EF',
          ],
          value3: 2,
          value4: [5],
          value5: [3],
          url: 'https://www.decathlon.com.cn/zh/p/light-support-fitness-bra-140/_/R-p-341163?mc=8757815&c=%E9%BB%91%E8%89%B2',
        },
        {
          title: '轻度支撑运动内衣 140 ',
          cc: '334247',
          name: 'image31.png',
          src: require('@/assets/images/334247.png'),
          type: 'Domyos',
          value: 1,
          value2: [
            '70A-70B',
            '75A-75B',
            '70C-70D',
            '80A-80B',
            '75C-75D',
            '85A-85B',
            '80C-80D',
            '90A-90B',
            '85C-85D',
            '90CD',
            '85EF',
          ],
          value3: 1,
          value4: [5, 1],
          value5: [3],
          url: 'https://www.decathlon.com.cn/zh/p/light-support-fitness-bra-140/_/R-p-334247?mc=8666328&c=??',
        },
      ],
      showImage: [],
      data1: [
        { id: 1, name: '轻运动类：瑜伽/普拉提/竞走/爬山' },
        { id: 2, name: '中度运动类：撸铁/自行车/舞蹈' },
        { id: 3, name: '高度运动类：跑步/球类/操课' },
      ],
      value1: [],
      value2: '',
      data3: [
        { id: 1, name: '日常短款' },
        { id: 2, name: '中长款' },
      ],
      value3: [],

      data4: [
        { id: 1, name: '可调节' },
        { id: 2, name: '前拉链' },
        { id: 3, name: '后背钩' },
        { id: 4, name: '一体式' },
        // { id: 5, name: '无特殊偏好' },
      ],
      value4: [],
      data5: [
        // { id: 1, name: "无痕" },
        // { id: 2, name: "美背" },
        { id: 3, name: '轻薄透气' },
        { id: 4, name: '美胸聚拢' },
        // { id: 5, name: "无特殊偏好" },
      ],
      value5: [],
      url: '',
    }
  },

  mounted () {},

  methods: {
    onClickLeft2 () {
      this.seq = 0
    },
    onClickLeft () {
      this.seq = 1
    },
    submit () {
      this.showImage = []
      // if (this.value3 === "") {
      //  this.$message({ message: "长短喜好不能为空 !", type: "error" });
      //  return;
      // }

      for (let i = 0; i < this.images.length; i++) {
        if (this.images[i].value2.indexOf(this.value2) > -1) {
          if (this.value1.indexOf(this.images[i].value) > -1) {
            // if (this.value3 !== "") {
            //  if (this.value3 !== this.images[i].value3) {
            //    continue;
            //  }
            // }

            if (this.value3.length > 0) {
              var ok3 = false
              // for (let j = 0; j < this.value3.length; j++) {
              //  const v = this.value4[j];
              //  if (this.images[i].value4.indexOf(v) > -1) {
              //    ok = true;
              //    break;
              //  }
              // }
              if (this.value3.indexOf(this.images[i].value3) > -1) {
                ok3 = true
              }
              if (ok3 === false) {
                continue
              }
            }

            if (this.value4.length > 0) {
              var ok = false
              for (let j = 0; j < this.value4.length; j++) {
                const v = this.value4[j]
                // if (v === 5) {
                //  ok = true
                //  break
                // }
                if (this.images[i].value4.indexOf(v) > -1) {
                  ok = true
                  break
                }
              }
              if (ok === false) {
                continue
              }
            }

            if (this.value5.length > 0) {
              var ok2 = false
              for (let j = 0; j < this.value5.length; j++) {
                const v2 = this.value5[j]
                // if (v2 === 5) {
                //  ok2 = true
                //  break
                // }
                if (this.images[i].value5.indexOf(v2) > -1) {
                  ok2 = true
                  break
                }
              }
              if (ok2 === false) {
                continue
              }
            }

            if (this.recommend[this.value2][this.images[i].type] !== '') {
              this.showImage.push({
                title: this.images[i].title,
                cc: this.images[i].cc,
                name: this.images[i].name,
                src: this.images[i].src,
                type: this.images[i].type,
                url: this.images[i].url,
              })
            }
          }
        }
      }
      this.seq = 1
    },
    openFilter () {
      console.log('click')
      this.seq = 4
    },
    to (image) {
      this.currentImage = image
      this.seq = 2
    },
    toUrl (image) {
      console.log(image.url)
      if (image.url !== '') {
        // location.href = image.url;
        window.open(image.url, '_blank')
      } else {
        // this.seq = 1;
      }
      // this.url = image.url;
      // this.seq = 5;
    },
    back () {
      this.seq = 0
    },
    filter () {
      this.error.value1 = false
      this.error.value2 = false
      if (this.value2 === '' || this.value1.length < 1) {
        this.$message({ message: '有必填项为空', type: 'error' })
        if (this.value2 === '') {
          this.error.value2 = true
        }

        if (this.value1.length < 1) {
          this.error.value1 = true
        }
        return
      }
      this.showImage = []
      for (let i = 0; i < this.images.length; i++) {
        if (this.images[i].value2.indexOf(this.value2) > -1) {
          if (this.value1.indexOf(this.images[i].value) > -1) {
            if (this.recommend[this.value2][this.images[i].type] !== '') {
              this.showImage.push({
                title: this.images[i].title,
                cc: this.images[i].cc,
                src: this.images[i].src,
                type: this.images[i].type,
                name: this.images[i].name,
                url: this.images[i].url,
              })
            }
          }
        }
      }
      this.seq = 1
    },
  },
}
</script>

<style lang="scss" scoped>
.loading-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #aedff8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.content {
  width: 100%;
  margin-top: 40%;
  text-align: center;
}
.input {
  height: 37px;
  line-height: 37px;
  border: 0px;
  color: #333333;
  font-size: 16px;
  background-color: #efefef;
}

::v-deep .el-form-item__error {
  width: 80%;
}
::v-deep .el-checkbox-button .el-checkbox-button__inner {
  border-radius: 20px;
  font-weight: 400;
  color: #121315;
  padding: 12px 20px;
  font-size: 14px;
  border-left: none;
  box-shadow: none !important;
}
::v-deep .el-radio-button .el-radio-button__inner {
  border-radius: 20px;
  font-weight: 400;
  padding: 12px 20px;
  color: #121315;
  font-size: 14px;
  border-left: none;
  box-shadow: none !important;
}
.title {
  margin-top: 30px;
  margin-left: 20px;
  font-weight: 400;
}
.my-swipe .van-swipe-item {
  font-size: 20px;
  text-align: center;
}
::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
  color: #ffffff;
  background-color: rgb(0, 128, 196);
  border-color: rgb(0, 128, 196);
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #ffffff;
  background-color: rgb(0, 128, 196);
  border-color: rgb(0, 128, 196);
}
::v-deep .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
  box-shadow: none;
}
</style>
